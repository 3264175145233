import React from "react";
import { Message } from "semantic-ui-react";

export class AuthError extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Message
          error
          header="Something went wrong."
          content="You are not authorized to perform this action."
        />
        <div>
          <a href="./">Return to start page</a>
        </div>
      </React.Fragment>
    );
  }
}

export default AuthError;
