import React from "react";

import {
  Segment,
  Header,
  Container,
  Button,
  Loader,
  Message,
  Image,
} from "semantic-ui-react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond-polyfill";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { ErrorMessage, ErrorMessageXMLParse } from "components/lib/error";

registerPlugin(FilePondPluginFileValidateType);

export class MetadataUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showValidationResult: false,
      validationResult: undefined,
      fileChosen: false,
      uploading: false,
      federationId: props.federationId,
      fedType: props.fedType,
    };

    this.uploadFile = this.uploadFile.bind(this);
  }

  componentDidMount() {
    this.setState({ federationId: this.props.federationId });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { metadataUploadResp } = nextProps;
    if (metadataUploadResp) {
      const resp = metadataUploadResp;
      if (resp.updated) {
        const validationResult = metadataUploadResp.data;
        this.setState({
          showValidationResult: true,
          validationResult,
          error: false,
          uploading: false,
        });
      } else if (resp.error && resp.error.statusCode === 400) {
        this.setState({
          uploadXMLParserError: resp.error,
          uploading: false,
          error: true,
        });
      } else {
        this.setState({
          uploadError: resp.error,
          uploading: false,
          error: true,
        });
      }
    }
  }

  handleFileChosen = (fileItems) => {
    this.props.handleFileChosen();
    if (fileItems[0]) {
      const file = fileItems[0].file;

      const allowedFileType = this.state.fedType === "fedtls" ? "json" : "xml";

      if (file.type === "text/xml" || file.type === "application/json") {
        this.setState({
          showValidationResult: false,
          validationResult: null,
          xmlFile: fileItems[0].file,
          fileChosen: true,
        });
      } else {
        this.setState({
          showValidationResult: false,
          validationResult: undefined,
          formatError: `Wrong format on uploaded file. Only .${allowedFileType} files are accepted.`,
        });
      }
    } else {
      this.setState({
        showValidationResult: false,
        validationResult: null,
        xmlFile: undefined,
        fileChosen: false,
        error: false,
        uploadXMLParserError: undefined,
        uploadError: undefined,
      });
    }
  };

  uploadFile() {
    this.setLoader(true);
    const { federationId, fedType, xmlFile } = this.state;
    const formData = new FormData();
    if (fedType === "fedtls") {
      formData.append("metadataFile", xmlFile);
      this.props.uploadMetadataRequest({
        federationId,
        formData,
        fedType: "fedtls",
      });
    } else {
      formData.append("samlFile", xmlFile);
      this.props.uploadMetadataRequest({
        federationId,
        formData,
        fedType: "saml",
      });
    }
  }

  setLoader(loading) {
    this.setState({ uploading: loading });
  }

  render() {
    const {
      validationResult,
      fileChosen,
      uploading,
      uploadError,
      uploadXMLParserError,
      formatError,
      federationId,
      fedType,
    } = this.state;
    return (
      <Container>
        <div className="logo-container">
          {federationId && federationId === "skolfed" && (
            <Image
              inline
              verticalAlign="middle"
              src={require(
                `themes-semantic-fedreg-skolfed/assets/images/skolfed/logo.png`,
              )}
              bordered={false}
              size="medium"
            />
          )}
          {federationId && federationId === "skolfed-trial" && (
            <Image
              inline
              verticalAlign="middle"
              src={require(
                `themes-semantic-fedreg-skolfed/assets/images/skolfed-trial/logo.png`,
              )}
              bordered={false}
              size="medium"
            />
          )}
          {federationId && federationId === "sambi" && (
            <Image
              inline
              verticalAlign="middle"
              src={require(
                `themes-semantic-fedreg-sambi/assets/images/sambi/logo.png`,
              )}
              bordered={false}
              size="medium"
            />
          )}
          {federationId && federationId === "sambi-trial" && (
            <Image
              inline
              verticalAlign="middle"
              src={require(
                `themes-semantic-fedreg-sambi/assets/images/sambi-trial/logo.png`,
              )}
              bordered={false}
              size="medium"
            />
          )}
          {federationId && federationId === "sambi-acc" && (
            <Image
              inline
              verticalAlign="middle"
              src={require(
                `themes-semantic-fedreg-sambi/assets/images/sambi-acc/logo.png`,
              )}
              bordered={false}
              size="medium"
            />
          )}
          {federationId && federationId === "kontosynk" && (
            <Image
              inline
              verticalAlign="middle"
              src={require(
                `themes-semantic-fedreg-skolfed/assets/images/kontosynk/logo.png`,
              )}
              bordered={false}
              size="medium"
            />
          )}
          {federationId && federationId === "kontosynk-trial" && (
            <Image
              inline
              verticalAlign="middle"
              src={require(
                `themes-semantic-fedreg-skolfed/assets/images/kontosynk-trial/logo.png`,
              )}
              bordered={false}
              size="medium"
            />
          )}
        </div>
        <Segment padded>
          <Header as="h1">Metadata validator</Header>
          <div style={{ padding: "2em" }}>
            <Header as="h3">
              Upload {fedType === "fedtls" ? "JSon" : "SAML"} metadata
              {fileChosen === false && !validationResult && ", choose file"}.
            </Header>
            {uploadXMLParserError && (
              <ErrorMessageXMLParse
                title="Failed to upload file"
                {...uploadXMLParserError}
              />
            )}
            {uploadError && (
              <ErrorMessage title="Failed to upload file" {...uploadError} />
            )}
            {formatError && (
              <Message
                error
                header="Failed to upload file"
                content={formatError}
              />
            )}
            <FilePond
              onupdatefiles={(fileItems) => this.handleFileChosen(fileItems)}
              acceptedFileTypes={
                fedType === "fedtls" ? "application/json" : "text/xml"
              }
            />
            {fileChosen === true && !validationResult && (
              <Button
                secondary
                style={{ marginLeft: "10px" }}
                onClick={() => this.uploadFile()}
              >
                Validate
              </Button>
            )}
            {uploading && <Loader active inline />}
          </div>
        </Segment>
      </Container>
    );
  }
}

export default MetadataUpload;
