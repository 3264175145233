import React from "react";
import { Route, Routes } from "react-router-dom";
import { AppContainer } from "../containers/app";

export const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AppContainer />} />
    </Routes>
  );
};
