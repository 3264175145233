import React from "react";
import PropTypes from "prop-types";
import { Table, Segment } from "semantic-ui-react";

const cellBorder = "solid 1px #ccc";
const style = {
  tableBorder: { borderTop: cellBorder, borderLeft: cellBorder },
  cellBorder: { borderBottom: cellBorder, borderRight: cellBorder },
  colors: {
    ok: { background: "lightgreen" },
    info: { background: "#fff" },
    warn: { background: "yellow" },
    error: { background: "red" }
  }
};

export const ValidationResultTable = ({ data }) => (
  <Segment>
    <Legend />
    <Table celled style={style.tableBorder}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            style={{
              borderTop: cellBorder,
              borderBottom: cellBorder,
              borderLeft: cellBorder,
              borderRight: cellBorder
            }}
          >
            Element
          </Table.HeaderCell>
          <Table.HeaderCell
            style={{
              borderTop: cellBorder,
              borderBottom: cellBorder,
              borderRight: cellBorder
            }}
          >
            Info
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data &&
          data.map(({ label, ok, error, warn, msg }, index) => (
            <Table.Row key={index}>
              <Table.Cell style={style.cellBorder}>
                <b>{label}</b>
              </Table.Cell>
              <Table.Cell
                style={{
                  padding: "0",
                  margin: "0",
                  ...style.cellBorder
                }}
              >
                {label === "FedTLSSchema" ? (
                  <InfoTableFedTLS
                    ok={ok}
                    error={error}
                    warn={warn}
                    msg={msg}
                  />
                ) : (
                  <InfoTableSAML ok={ok} error={error} warn={warn} msg={msg} />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  </Segment>
);

const InfoTableSAML = ({ ok, error, warn, msg }) => (
  <Table celled style={{ padding: "0", margin: "0" }}>
    <Table.Body>
      {ok.map((infoText, index) => (
        <Table.Row key={index} style={{ background: "lightgreen" }}>
          <Table.Cell>{infoText}</Table.Cell>
        </Table.Row>
      ))}
      {error.map((infoText, index) => (
        <Table.Row key={index} style={{ background: "red" }}>
          <Table.Cell>
            {}
            {infoText}
          </Table.Cell>
        </Table.Row>
      ))}
      {warn.map((infoText, index) => (
        <Table.Row key={index} style={{ background: "yellow" }}>
          <Table.Cell>{infoText}</Table.Cell>
        </Table.Row>
      ))}
      {msg.map((infoText, index) => (
        <Table.Row key={index} style={{ background: "#fff" }}>
          <Table.Cell>{infoText}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

const InfoTableFedTLS = ({ ok, error, warn, msg }) => (
  <Table celled style={{ padding: "0", margin: "0" }}>
    <Table.Body>
      {ok.map((infoText, index) => (
        <Table.Row key={index} style={{ background: "lightgreen" }}>
          <Table.Cell>{infoText}</Table.Cell>
        </Table.Row>
      ))}
      {error.map((infoText, index) => (
        <Table.Row key={index} style={{ background: "red" }}>
          <Table.Cell>
            <FedTLSErrorDataView data={infoText} />
          </Table.Cell>
        </Table.Row>
      ))}
      {warn.map((infoText, index) => (
        <Table.Row key={index} style={{ background: "yellow" }}>
          <Table.Cell>{infoText}</Table.Cell>
        </Table.Row>
      ))}
      {msg.map((infoText, index) => (
        <Table.Row key={index} style={{ background: "#fff" }}>
          <Table.Cell>{infoText}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

const FedTLSErrorDataView = ({ data }) => {
  const dataJson =
    data !== undefined && data.constructor === String
      ? JSON.parse(data)
      : data.constructor === Object
      ? data
      : undefined;
  return (
    <div>
      {dataJson === undefined && <div>Could not parse error message!</div>}
      {dataJson &&
        dataJson.causingExceptions &&
        dataJson.causingExceptions.map((child, index) => {
          return (
            <React.Fragment key={index}>
              {true && (
                <ul key={index}>
                  <li>
                    <span>Message: </span>
                    <span>{child.message}</span>
                  </li>
                  <li>
                    <span>Location: </span>
                    <span>{child.schemaLocation}</span>
                  </li>
                  <li>
                    <span>Pointer: </span>
                    <span>{child.pointerToViolation}</span>
                  </li>
                </ul>
              )}
              {child.causingExceptions && <FedTLSErrorDataView data={child} />}
            </React.Fragment>
          );
        })}
    </div>
  );
};

ValidationResultTable.propTypes = {
  data: PropTypes.object.isRequired
};
const Legend = () => (
  <React.Fragment>
    <Box color={style.colors.ok} text="OK" />
    <Box
      color={style.colors.warn}
      text="Warning (Needs to be checked and perhaps fixed)"
    />
    <Box color={style.colors.error} text="Error (Must be fixed)" />
    <Box color={style.colors.info} text="Informational message" />
  </React.Fragment>
);

const Box = ({ color, text }) => (
  <span style={{ margin: "0 0.3em" }}>
    <span
      style={{
        display: "inline-block",
        border: "solid 1px #ccc",
        width: "16px",
        height: "16px",
        margin: "0 0.3em",
        ...color
      }}
    ></span>
    {text}
  </span>
);

export default ValidationResultTable;
