import React from "react";
import PropTypes from "prop-types";
import { Image } from "semantic-ui-react";
import logo from "./iis.png";

export const IISLogo = ({
  verticalAlign = "top",
  left = "true",
  size = "tiny"
}) => (
  <Image
    inline
    verticalAlign={verticalAlign}
    left={left}
    size={size}
    src={logo}
  />
);

IISLogo.propTypes = {
  size: PropTypes.oneOf([
    "mini",
    "tiny",
    "small",
    "medium",
    "large",
    "big",
    "huge",
    "massive"
  ]),
  verticalAlign: PropTypes.oneOf(["bottom", "middle", "top"]),
  left: PropTypes.bool
};

export default IISLogo;
