import { combineReducers } from "redux";
import { metadataUploadResp } from "./metadata/reducers";

// EXPORT ALL ACTIONS ////////////////////////////////////////
export * from "./metadata/actions";

export const createRootReducer = () =>
  combineReducers({
    metadataUploadResp,
  });

export default createRootReducer;
