import React, { Component } from "react";
import { MetadataUpload } from "./MetadataUpload";
import { Container } from "semantic-ui-react";
import { ValidationResultTable } from "components/lib/metadata";

export class MetaDataValidatorView extends Component {
  constructor(props) {
    super(props);
    this.state = { showResult: false };
    this.handleFileChosen = this.handleFileChosen.bind(this);
    this.uploadMetadataRequest = this.uploadMetadataRequest.bind(this);
  }

  handleFileChosen() {
    this.setState({
      showResult: false,
    });
  }

  uploadMetadataRequest(data) {
    this.props.uploadMetadataRequest(data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { metadataUploadResp } = nextProps;
    if (
      metadataUploadResp &&
      metadataUploadResp.type === "UPLOAD_METADATA_SUCCESS"
    )
      if (metadataUploadResp.data) {
        this.setState({
          samlResponse: metadataUploadResp.data,
          showResult: true,
          metadataUploadResp,
        });
      }
  }

  render() {
    const { samlResponse, metadataUploadResp, showResult } = this.state;
    const { federationId, fedType } = this.props;
    return (
      <React.Fragment>
        {!federationId && !fedType && <div>Configuration error!</div>}
        {federationId && fedType && (
          <MetadataUpload
            handleFileChosen={this.handleFileChosen}
            handleUploadValidation={this.handleUploadValidation}
            uploadMetadataRequest={this.uploadMetadataRequest}
            metadataUploadResp={metadataUploadResp}
            federationId={federationId}
            fedType={fedType}
          />
        )}
        {showResult === true && (
          <Container style={{ marginTop: "1em" }}>
            <ValidationResultTable data={samlResponse} />
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default MetaDataValidatorView;
