import React from "react";
import PropTypes from "prop-types";
import { Icon, Message, MessageContent, Label } from "semantic-ui-react";

const mailLink = () => (
  <Label as="a">
    <Icon name="mail" />{" "}
    <a className="button" href="mailto:federationer@internetstiftelsen.se">
      federationer@internetstiftelsen.se
    </a>
  </Label>
);

const messageText =
  "Please check that the file is valid XML and in accordance with SAML 2.0 Metadata standards." +
  " If you are having further problems with uploading the metadata, please contact support at ";

export const WarningMessageXMLParse = ({
  title = "Something went wrong.",
  statusCode,
  statusMessage,
  message
}) => (
  <Message warning>
    <Message.Header>{title}</Message.Header>
    <MessageContent style={{ fontSize: 12 }} content={messageText} />
    {mailLink()}
    <Message.List style={{ fontSize: 12 }}>
      Common causes:
      <Message.Item>
        The file contains metadata for several entities.
      </Message.Item>
      <Message.Item>XML namespaces are incorrect.</Message.Item>
      <Message.Item>
        The metadata root element is anything other than EntityDescriptor.
      </Message.Item>
    </Message.List>
  </Message>
);

WarningMessageXMLParse.propTypes = {
  title: PropTypes.string.isRequired,
  statusCode: PropTypes.number,
  statusMessage: PropTypes.string,
  message: PropTypes.string
};

export default WarningMessageXMLParse;
