import { configureStore } from "@reduxjs/toolkit";
import { logger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { createRootReducer } from "./ducks";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({ thunk: false }).concat(logger, sagaMiddleware);

const store = configureStore({
  reducer: createRootReducer(),
  middleware,
  devTools: process.env.NODE_ENV === "development",
});

sagaMiddleware.run(rootSaga);

export default store;
